import { Col, Row, Spin, Tooltip } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import {
  LoadingOutlined,
  CloseCircleOutlined,
  RedoOutlined,
  EditOutlined,
} from '@ant-design/icons';
import CustomTable from '../../components/CustomTable';
import { ApplicationState } from '../../store';
import { loadDetail, loadRequest, loadSteps } from '../../store/ducks/myOrdersDetail/actions';
import { configToColumnsType, dataToDisplay, dataToTableData } from '../../utils/newTableHelper';
import FIlesSelect from './FIlesSelect';
import {
  Buttons,
  Container,
  ContainerData,
  ContentData,
  ContentDetail,
  CustomSteps,
  FooterButton,
  ItemData,
  TitleData,
  ValueData,
} from './styles';
import { baseURL } from '../../services/api';
import { hasPermission } from '../../utils/permissions';
import { MESSAGES, ROLE } from '../../utils/constants';
import { editOrderRequest, reDoOrderRequest } from '../../store/ducks/shoppingCart/actions';
import Swal from 'sweetalert2';
import ServerError from '../../components/ServerError';
import { useWindowSize } from '../../customHooks';
import AttachmentFile from '../../components/AttachmentFile';

const { Step } = CustomSteps;

interface Option {
  value: number | string;
}

const OrderDetails: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.myOrdersDetail.config);
  const error = useSelector((state: ApplicationState) => state.myOrdersDetail.errorData);
  const data = useSelector((state: ApplicationState) => state.myOrdersDetail.data);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const configDetail = useSelector((state: ApplicationState) => state.myOrdersDetail.configDetail);
  const dataDetail = useSelector((state: ApplicationState) => state.myOrdersDetail.dataDetail);
  const steps = useSelector((state: ApplicationState) => state.myOrdersDetail.steps);
  const loading = useSelector((state: ApplicationState) => state.myOrdersDetail.loading);
  const loadingTable = useSelector((state: ApplicationState) => state.myOrdersDetail.loadingTable);
  const pagination = useSelector((state: ApplicationState) => state.myOrdersDetail.pagination);
  const cartQuantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);

  const [dataList, setDataList] = useState<any[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [displayFiles, setDisplayFiles] = useState<boolean>(false);

  const size = useWindowSize();

  const dispatch = useDispatch<Dispatch<Action>>();
  const { id }: any = useParams();

  const redo = useCallback(
    (nuNota: number, isEdit?: boolean) => {
      if (cartQuantity > 0) {
        Swal.fire({
          title: 'Aviso!',
          confirmButtonColor: layout?.color.default,
          html: 'Você já tem itens adicionados no seu carrinho. Deseja limpar o carrinho?',
          icon: 'warning',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed)
            dispatch(isEdit ? editOrderRequest(nuNota) : reDoOrderRequest(nuNota));
        });
      } else {
        dispatch(isEdit ? editOrderRequest(nuNota) : reDoOrderRequest(nuNota));
      }
    },
    [cartQuantity, dispatch, layout?.color.default]
  );

  useEffect(() => {
    dispatch(loadRequest(undefined, undefined, undefined, id));
    dispatch(loadDetail(id));
    dispatch(loadSteps(id));
  }, [dispatch, token, id]);

  useEffect(() => {
    setDataList(dataToDisplay(dataDetail, configDetail) || []);
  }, [dataDetail, configDetail]);

  useEffect(() => {
    const files = dataList.find((item) => item.title === 'ANEXOS');
    if (files) {
      setOptions(files.value.map((item: any) => ({ value: item.value })));
      setDisplayFiles(files.show);
    }
  }, [dataList]);

  useEffect(() => {
    setCanceled(!!steps.find((item) => item?.fixedStatus === 'CANCELADO' && item?.isCurrent));
  }, [steps]);
  return (
    <>
      {error ? (
        <ServerError status={error.status} message={error.message} back />
      ) : (
        <Container>
          <Buttons>
            {configDetail?.details.attachDocuments && (
              <AttachmentFile
                style={{ marginBottom: (size.width || 0) <= 1024 ? '20px' : '0px' }}
                url={`${baseURL}/order/${id}/upload`}
                accept={configDetail?.details.fileType}
                type="primary"
              />
            )}
            {hasPermission([ROLE.REDO_ORDER], roles) && (
              <FooterButton
                data-cy="btn-redo"
                onClick={() => redo(id)}
                icon={<RedoOutlined />}
                type="primary"
              >
                Refazer
              </FooterButton>
            )}
            {hasPermission([ROLE.EDIT_ORDER], roles) && (
              <>
                {!dataList.find((element) => element.key === 'ORIGEMPEC')?.value ||
                !dataList.find((element) => element.key === 'PERMITE_EDICAO')?.value ? (
                  <Tooltip
                    title={
                      !dataList.find((element) => element.key === 'ORIGEMPEC')?.value
                        ? MESSAGES.EDIT_ORDER_ORIGIN
                        : MESSAGES.EDIT_ORDER_CONFIRMED
                    }
                  >
                    <FooterButton
                      data-cy="btn-editOrder"
                      onClick={() => redo(id, true)}
                      block={(size?.width || 0) <= 1024}
                      icon={<EditOutlined />}
                      disabled={
                        !dataList.find((element) => element.key === 'ORIGEMPEC')?.value ||
                        !dataList.find((element) => element.key === 'PERMITE_EDICAO')?.value
                      }
                      type="primary"
                    >
                      Editar
                    </FooterButton>
                  </Tooltip>
                ) : (
                  <FooterButton
                    data-cy="btn-editOrder"
                    onClick={() => redo(id, true)}
                    icon={<EditOutlined />}
                    type="primary"
                  >
                    Editar
                  </FooterButton>
                )}
              </>
            )}
          </Buttons>
          <Spin
            style={{ color: layout?.color.default }}
            spinning={loading}
            delay={500}
            indicator={<LoadingOutlined />}
          >
            <CustomSteps
              status={canceled ? 'error' : undefined}
              labelPlacement="vertical"
              size="small"
              layout={layout}
              direction={(size.width || 0) < 700 ? 'vertical' : 'horizontal'}
              canceled={canceled}
              current={steps.findIndex((item) => item.isCurrent)}
            >
              {steps.map((item) => (
                <Step title={item.title} icon={canceled ? <CloseCircleOutlined /> : undefined} />
              ))}
            </CustomSteps>
          </Spin>
          <ContentDetail>
            <CustomTable
              style={{ padding: '20px' }}
              onChange={(pg: any, filters: any, sorter: any) => {
                dispatch(loadRequest(pg, filters, sorter, id));
              }}
              pagination={pagination}
              loading={loadingTable}
              columns={configToColumnsType(config?.fields.filter((item) => item.show) || [])}
              data={dataToTableData(data, config)}
            />
            <Spin
              style={{ color: layout?.color.default }}
              spinning={loading}
              delay={500}
              indicator={<LoadingOutlined />}
            >
              <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
                <Col
                  xs={24}
                  sm={24}
                  md={displayFiles ? 14 : 24}
                  lg={displayFiles ? 14 : 24}
                  xl={displayFiles ? 14 : 24}
                >
                  <ContainerData>
                    <h3>Informações do pedido</h3>
                    <ContentData>
                      {dataList.map((item: any) => {
                        if (!item.display) {
                          return undefined;
                        }
                        return (
                          <ItemData>
                            <TitleData>{`${item.title}:`}</TitleData>
                            <ValueData>{item.value}</ValueData>
                          </ItemData>
                        );
                      })}
                    </ContentData>
                  </ContainerData>
                </Col>
                {displayFiles && (
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <FIlesSelect id={id} options={options} />
                  </Col>
                )}
              </Row>
            </Spin>
          </ContentDetail>
        </Container>
      )}
    </>
  );
};

export default OrderDetails;
