import { Button, Steps, Upload } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ContentDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ContainerData = styled.div<{ hasFiles?: boolean }>`
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const CustomSteps = styled(Steps)<{ canceled?: boolean; layout?: Layout }>`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ layout, canceled }) => (!canceled ? layout?.color.default : colors.writeGrey)};
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${({ layout, canceled }) => (!canceled ? layout?.color.default : '#f0f0f0')};
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${({ layout, canceled }) => (!canceled ? layout?.color.default : colors.writeGrey)};
    border-color: ${({ layout, canceled }) =>
      !canceled ? layout?.color.default : colors.writeGrey};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ layout, canceled }) =>
      !canceled ? layout?.color.default : colors.writeGrey};
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ layout, canceled }) => (!canceled ? layout?.color.default : colors.writeGrey)};
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${colors.writeGrey};
  }
`;

export const Buttons = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ContentData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ContainerFiles = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  height: 100%;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const ContentFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const ItemData = styled.div`
  width: 100%;
  padding-right: 6px;
  display: flex;
  flex-direction: row;
`;

export const TitleData = styled.h4`
  font-weight: 500;
  font-size: 14px;
  margin-right: 4px;
`;

export const ValueData = styled.span`
  font-size: 14px;
`;

export const CustomButton = styled(Button)<{ layout?: Layout }>`
  margin-right: 5px;
  :hover {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;

export const UploadFullWidth = styled(Upload)`
  .ant-upload {
    width: 100%;
    margin-bottom: 0px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }
`;

export const FooterButton = styled(Button)`
  margin-left: 20px;
  margin-bottom: 0px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 20px;
    margin-left: 0px;
  }
`;
